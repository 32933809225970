<script setup lang="ts">
import type { FormError, FormErrorEvent, FormEventType, FormSubmitEvent } from '#ui/types'
import type { BaseLayerTypeEnum, SectorTypeEnum } from '@forgd/supabase'
import type { z } from 'zod'
import { UpdateListedProjectBody, UpdateNotListedProjectBody } from '@forgd/contract'

import { type DateValue, getLocalTimeZone, today } from '@internationalized/date'
import { format } from 'date-fns'
import type { ComboboxOption } from '~/core/components/form/UiCombobox.vue'
import UiDatePicker from '~/core/components/form/UiDatePicker.vue'
import UiProjectImageUpload from '~/core/components/form/UiProjectImageUpload.vue'

const model = defineModel<TokenDetails>()

const client = useClient()
const onboarding = useOnboardingV2()

const schema = onboarding.tokenFindState === 'token-not-listed' ? UpdateNotListedProjectBody : UpdateListedProjectBody

export type TokenDetails = z.infer<typeof schema>

interface SectorOption {
  label: string
  value: string
  enum: keyof typeof SectorTypeEnum
}

const sectorOptions: SectorOption[] = [
  { label: 'DeFi', value: 'DeFi', enum: 'DeFi' },
  { label: 'GameFi', value: 'GameFi', enum: 'GameFi' },
  { label: 'Social', value: 'Social', enum: 'Social' },
  { label: 'DePIN', value: 'DePIN', enum: 'DePin' },
  { label: 'AI & Data', value: 'AI & Data', enum: 'AiAndData' },
  { label: 'Base Layer', value: 'Base Layer', enum: 'BaseLayer' },
  { label: 'Memecoin', value: 'Memecoin', enum: 'Memecoin' },
  { label: 'Wallet', value: 'Wallet', enum: 'Wallet' },
  { label: 'Gambling', value: 'Gambling', enum: 'Gambling' },
  { label: 'Oracle', value: 'Oracle', enum: 'Oracle' },
  { label: 'eCommerce', value: 'eCommerce', enum: 'ECommerce' },
]

interface BaseLayerOption {
  label: string
  value: string
  coingeckoId?: string
  enum: keyof typeof BaseLayerTypeEnum
}

const baseLayerOptions: Array<BaseLayerOption> = [
  { label: 'Bitcoin', value: 'Bitcoin', coingeckoId: 'bitcoin', enum: 'Bitcoin' },
  { label: 'Ethereum', value: 'Ethereum', coingeckoId: 'ethereum', enum: 'Ethereum' },
  { label: 'Solana', value: 'Solana', coingeckoId: 'solana', enum: 'Solana' },
  { label: 'Aptos', value: 'Aptos', coingeckoId: 'aptos', enum: 'Aptos' },
  { label: 'Arbitrum', value: 'Arbitrum', coingeckoId: 'arbitrum-one', enum: 'Arbitrum' },
  { label: 'Avalanche', value: 'Avalanche', coingeckoId: 'avalanche', enum: 'Avalanche' },
  { label: 'Injective', value: 'Injective', coingeckoId: 'injective', enum: 'Injective' },
  { label: 'Optimism', value: 'Optimism', coingeckoId: 'optimism', enum: 'Optimism' },
  { label: 'Polkadot', value: 'Polkadot', coingeckoId: 'polkadot', enum: 'Polkadot' },
  { label: 'Radix Ecosystem', value: 'Radix Ecosystem', coingeckoId: 'radix', enum: 'RadixEcosystem' },
  { label: 'Sei', value: 'Sei', coingeckoId: 'sei', enum: 'Sei' },
  { label: 'Sui', value: 'Sui', coingeckoId: 'sui', enum: 'Sui' },
  { label: 'TRON', value: 'TRON', coingeckoId: 'tron', enum: 'Tron' },
  { label: 'Other', value: 'Other', enum: 'Other' },
]

type State = 'initial' | 'error' | 'filled' | 'saved'

const state = ref<State>('initial')

const form = reactive({
  coingeckoId: model.value?.coingeckoId || '',
  image: model.value?.image || '',
  name: model.value?.name || '',
  ticker: model.value?.ticker || '',
  tgeDate: model.value?.tgeDate ? new Date(model.value.tgeDate).toISOString().split('T')[0] : null,
  link: model.value?.link || '',
  sector: [],
  baseLayer: [],
})

const selectedBaseLayer = ref<ComboboxOption[]>([])

const selectedSectors = ref<ComboboxOption[]>([])

function selectBaseLayer(value: ComboboxOption[]) {
  selectedBaseLayer.value = value
  form.baseLayer = value.map(s => s.value as BaseLayerTypeEnum)
}

// try to auto-fill selectedBaseLayer if we get one from Coingecko
watch(model, (value) => {
  if (value?.baseLayerId) {
    const find = baseLayerOptions.find(option => option.coingeckoId === value.baseLayerId)
    if (find) {
      selectBaseLayer([find])
    }
  }
}, { immediate: true })

// watch the model for a new selected token and update the form
watch(model, (newVal) => {
  if (newVal) {
    form.name = newVal.name
    form.ticker = newVal.ticker
    form.tgeDate = newVal.tgeDate
    form.link = newVal.link
    form.image = newVal.image || ''
    form.sector = []
    form.baseLayer = []
  }
  if (newVal.coingeckoId) {
    form.coingeckoId = newVal.coingeckoId
  }
  else {
    form.coingeckoId = null
  }
})

/**
 * Before the initial submit, we only validate on submit.
 * After the initial submit, we validate on blur as well.
 */
const validateOn = computed<FormEventType[]>(() => state.value === 'initial' ? ['submit'] : ['submit', 'blur'])

const contactError = ref(false)

function validate(state: any): FormError[] {
  const errors: FormError[] = []

  if (onboarding.tokenFindState === 'token-not-listed') {
    if (!state.name) {
      errors.push({ path: 'name', message: 'Required' })
    }
  }
  else {
    for (const key in state) {
      if (key === 'coingeckoId' || key === 'image') {
        continue
      }
      if (!state[key]) {
        errors.push({ path: key, message: 'Required' })
        if (key === 'contact') {
          contactError.value = true
        }
      }
    }
  }
  return errors
}

/**
 * The Radix combobox isn't a Nuxt UI native thing, so
 * watch selectedBaseLayer and selectedSectors and manually
 * trigger the form validation when they change
 */

const formRef = ref<HTMLFormElement | null>(null)
async function validateForm() {
  try {
    await formRef.value?.validate()
  }
  catch {
    // avoid uncaught error in console
  }
}
watch(selectedSectors, async (val) => {
  form.sector = val.map(s => s.value as SectorTypeEnum)
  validateForm()
}, { deep: true })

watch(selectedBaseLayer, async (val) => {
  selectBaseLayer(val)
  validateForm()
}, { deep: true })

// manually trigger validation when the form is in an error state and the tgeDate changes
watch(form, async (newVal) => {
  if (state.value !== 'initial' && newVal.tgeDate) {
    validateForm()
  }
})

async function onSubmit(_event: FormSubmitEvent<any>) {
  const result = schema.safeParse(form)
  if (result.success) {
    const { image, coingeckoId, ...formData } = result.data

    const body = onboarding.tokenFindState === 'token-not-found'
      ? {
          ...formData,
          coingeckoId: null,
          tokenListed: false,
          hasToken: true,
        }
      : {
          ...formData,
          image: image && image.length > 0 ? image : undefined,
          coingeckoId,
          tokenListed: !!coingeckoId,
          hasToken: true,
        }

    const updateProjectDetails = onboarding.tokenFindState === 'token-selected' ? client.onboarding.updateListedProjectDetails : client.onboarding.updateNotListedProjectDetails

    const res = await updateProjectDetails({
      params: {
        projectId: onboarding.projectId!,
      },
      body,
    })

    if (res.status === 200) {
      state.value = 'saved'
      onboarding.next()
      return
    }
  }
  state.value = 'error'
}

async function onError(_event: FormErrorEvent) {
  state.value = 'error'
}

const uiButton = {
  base: 'h-[50px]',
  rounded: 'rounded-lg',
  variant: {
    solid: 'shadow-none bg-forgd-primary-900 hover:bg-forgd-primary-900 disabled:bg-forgd-neutral-600/30 disabled:border disabled:border-forgd-neutral-600 disabled:text-forgd-neutral-600',
  },
}

const uiInput = {
  base: 'h-[50px]',
  rounded: 'rounded-lg',
  color: {
    white: {
      outline: 'shadow-none ring-forgd-bgd-600 disabled:bg-forgd-bgd-200',
    },
  },
  padding: {
    sm: 'px-4',
  },
  size: {
    sm: 'text-base',
  },
}

const uiSelect = {
  base: 'h-[50px]',
  color: {
    white: {
      outline: 'shadow-none ring-forgd-bgd-600 disabled:bg-forgd-bgd-200',
    },
  },
  padding: {
    sm: 'px-4',
  },
  rounded: 'rounded-lg',
}

const uFormNoError = {
  error: 'hidden',
}

function fixUrl() {
  if (form.link.length > 0 && !form.link.startsWith('https://')) {
    form.link = `https://${form.link}`
  }
}

const localDate = today(getLocalTimeZone())

function isDateDisabled(date: DateValue) {
  // post-TGE projects need to be able to select past dates
  if (onboarding.tokenIsListed) {
    return false
  }
  else {
    return date.compare(localDate) < 0
  }
}
</script>

<template>
  <UCard>
    <div class="p-5 space-y-5">
      <div class="font-semibold">
        Please provide some details related to your token.
        <div v-if="onboarding.tokenFindState !== 'token-not-listed'" class="text-xs text-forgd-gray-600 font-normal">
          All fields are mandatory
        </div>
      </div>

      <UForm
        ref="formRef"
        :validate="validate"
        :schema="schema"
        :state="form"
        class="space-y-5"
        :validate-on="validateOn"
        @submit="onSubmit"
        @error="onError"
      >
        <div v-if="model?.coingeckoId">
          <UFormGroup label="CoinGecko URL" name="coinGeckoURL">
            <UInput :model-value="`https://www.coingecko.com/en/coins/${model.coingeckoId}`" :ui="uiInput" disabled />
            <template #error />
          </UFormGroup>
        </div>
        <div class="grid grid-cols-2 gap-5">
          <UFormGroup label="Token name" name="name" :ui="uFormNoError">
            <UInput v-model="form.name" :ui="uiInput" />
          </UFormGroup>
          <UFormGroup label="Token ticker" name="ticker" :ui="uFormNoError">
            <UInput v-model="form.ticker" :ui="uiInput" />
          </UFormGroup>

          <UFormGroup label="Sector" name="sector" :ui="uFormNoError">
            <OnboardingV2Combobox v-model="selectedSectors" :options="sectorOptions" :ui="uiSelect" />
          </UFormGroup>

          <UFormGroup label="Token website" name="link" :ui="uFormNoError">
            <UInput v-model="form.link" :ui="uiInput" @blur="fixUrl" />
          </UFormGroup>

          <UFormGroup label="Base layer" name="baseLayer" :ui="uFormNoError">
            <OnboardingV2Combobox v-model="selectedBaseLayer" :options="baseLayerOptions" :ui="uiSelect" />
          </UFormGroup>

          <UFormGroup label="Date of TGE" name="tgeDate" :ui="uFormNoError">
            <UiDatePicker v-model="form.tgeDate" :is-date-disabled="isDateDisabled" :use-available-days="false">
              <div class="h-[50px] rounded-lg flex items-center justify-between px-4 text-base">
                {{ form.tgeDate ? format(new Date(form.tgeDate), 'MM/dd/yyyy') : 'Select date' }}
                <UIcon name="i-heroicons-calendar" class="w-5 h-5" />
              </div>
            </UiDatePicker>
          </UFormGroup>
        </div>

        <UFormGroup name="image">
          <UiProjectImageUpload :image="form.image" @update:image="form.image = $event" />
        </UFormGroup>

        <UButton block :ui="uiButton" type="submit">
          Continue
          <template #trailing>
            <UIcon name="i-heroicons-arrow-right" class="w-4 h-4" />
          </template>
        </UButton>
      </UForm>
      
    </div>
  </UCard>
</template>
