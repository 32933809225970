import { useWindowSize } from '@vueuse/core'

export default defineNuxtRouteMiddleware(async (to) => {
  const auth = useAuth()
  const { width } = useWindowSize()
  const { minimumWidth } = useRuntimeConfig().public.featureFlags.mobileGate
  if (width.value < minimumWidth && to.path !== '/mobile-gate') {
    auth.redirectTo = to.path
    return navigateTo('/mobile-gate')
  }
})
