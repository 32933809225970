import { z } from 'zod'
import { stringToIntNumber } from '../../helper'

export const GetLiquidityBreakdownsQuery = z.object({
  date: z.string().date(),
  marketMaker: z.string().uuid().optional().nullable(),
  orderBook: z.string().optional().nullable(),
  exchange: z.string().optional().nullable(),
  daysLookback: z
    .string()
    .optional()
    .nullable()
    .transform(stringToIntNumber)
    .refine((val) => val == null || val > 0, {
      message: 'daysLookback must be a positive integer',
    }),
})

export type GetLiquidityBreakdownsQueryType = z.infer<typeof GetLiquidityBreakdownsQuery>

export const GetLiquidityBreakdownsResult = z.array(
  z.object({
    allOthersAsk100: z.number(),
    allOthersAsk100Percentage: z.number(),
    allOthersAsk200: z.number(),
    allOthersAsk200Percentage: z.number(),
    allOthersAsk50: z.number(),
    allOthersAsk50Percentage: z.number(),
    allOthersBid100: z.number(),
    allOthersBid100Percentage: z.number(),
    allOthersBid200: z.number(),
    allOthersBid200Percentage: z.number(),
    allOthersBid50: z.number(),
    allOthersBid50Percentage: z.number(),
    allOthersBidAsk100: z.number(),
    allOthersBidAsk100Percentage: z.number(),
    allOthersBidAsk200: z.number(),
    allOthersBidAsk200Percentage: z.number(),
    allOthersBidAsk50: z.number(),
    allOthersBidAsk50Percentage: z.number(),
    ask100: z.number(),
    ask100Percentage: z.number(),
    ask200: z.number(),
    ask200Percentage: z.number(),
    ask50: z.number(),
    ask50Percentage: z.number(),
    bid100: z.number(),
    bid100Percentage: z.number(),
    bid200: z.number(),
    bid200Percentage: z.number(),
    bid50: z.number(),
    bid50Percentage: z.number(),
    bidAsk100: z.number(),
    bidAsk100Percentage: z.number(),
    bidAsk200: z.number(),
    bidAsk200Percentage: z.number(),
    bidAsk50: z.number(),
    bidAsk50Percentage: z.number(),
  }),
)

export type GetLiquidityBreakdownsResultType = z.infer<typeof GetLiquidityBreakdownsResult>
