import type { TracingEventType } from '@forgd/contract/contract/tracing'
import { createConsola } from 'consola'
import { defu } from 'defu'

const MaxQueueSize = 10

const logger = createConsola({
  level: import.meta.env.DEBUG ? 5 : 3,
  defaults: {
    tag: 'Forgd Tracing',
  },
})

function createTracer(options?: { defaultEventData?: TracingEventType['eventData'] }) {
  const defaults = defu(options?.defaultEventData || {}, {
    userAgent: navigator.userAgent,
  })
  const auth = useAuth()
  const route = useRoute()
  const client = useClient()
  const queue: TracingEventType[] = []
  let flushQueued: boolean = false
  async function flush() {
    const body = [...queue.splice(0, MaxQueueSize)]
      .map(event => defu(event, { eventData: defaults }))
    if (import.meta.dev) {
      logger.debug('Flushing queue', body)
    }
    else if (body.length) {
      await client.tracing.events({ body })
    }
    flushQueued = false
    if (queue.length > 0) {
      queueFlush()
    }
  }
  function queueFlush() {
    if (!flushQueued) {
      flushQueued = true
      onNuxtReady(() => {
        flush()
      })
    }
  }
  return {
    pageView(data?: TracingEventType['eventData']) {
      if (!auth.loggedIn) {
        return
      }
      const payload = defu(
        { eventData: data },
        { eventData: { path: route.fullPath }, projectId: auth.project?.id, eventType: 'page_view' },
      )
      logger.debug('Page view', payload)
      queue.push(payload)
      queueFlush()
    },
    custom(eventType: string, data: TracingEventType['eventData']) {
      if (!auth.loggedIn) {
        return
      }
      const payload = defu(
        { eventData: data },
        { eventData: { path: route.fullPath }, projectId: auth.project?.id, eventType },
      )
      logger.debug(`Custom: ${eventType}`, payload)
      queue.push(payload)
      queueFlush()
    },
  }
}

export function useTracer(): ReturnType<typeof createTracer> {
  const nuxtApp = useNuxtApp()
  // singleton
  return nuxtApp._tracer || createTracer()
}
