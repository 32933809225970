<script setup lang="ts">
import type { FormError, FormErrorEvent, FormEventType, FormSubmitEvent } from '#ui/types'
import { type MemberContactType, MemberDetailsBody } from '@forgd/contract'

const schema = MemberDetailsBody.omit({
  projectId: true,
  email: true,
})

interface ContactTypeOption {
  label: string
  value: string
  enum: keyof typeof MemberContactType
}

const contactTypes: Array<ContactTypeOption> = [
  { label: 'Telegram', enum: 'Telegram', value: 'telegram' },
  { label: 'Email', enum: 'Email', value: 'email' },
]

type State = 'initial' | 'error' | 'filled'

const client = useClient()
const onboarding = useOnboardingV2()
const auth = useAuth()

const state = ref<State>('initial')

const form = reactive({
  firstName: '',
  lastName: '',
  contactType: 'telegram',
  contact: '',
  organizationName: '',
  position: '',
})

const canSubmit = computed(() => {
  for (const key in form) {
    if (form[key as keyof typeof form] === '') {
      return false
    }
  }
  return true
})

/**
 * Before the initial submit, we only validate on submit.
 * After the initial submit, we validate on blur as well.
 */
const validateOn = computed<FormEventType[]>(() => state.value === 'initial' ? ['submit'] : ['submit', 'blur'])

const contactError = ref(false)

function validate(state: any): FormError[] {
  const errors: FormError[] = []
  for (const key in state) {
    if (!state[key]) {
      errors.push({ path: key, message: 'Required' })
      if (key === 'contact') {
        contactError.value = true
      }
    }
  }
  return errors
}

function validateContact() {
  if (!form.contact) {
    contactError.value = true
  }
  else {
    contactError.value = false
  }
}

async function onSubmit(_event: FormSubmitEvent<any>) {
  const res = await client.onboarding.updateMemberDetails({
    body: {
      ...form,
      contactType: form.contactType as MemberContactType,
      projectId: onboarding.projectToJoin!.id!,
      email: auth.me!.email,
    },
  })

  if (res.status === 200) {
    // forcing auth check to refresh me info
    auth.refresh({ from: 'OnboardingV2TeammateDetails' })
    onboarding.next()
  }
  else {
    state.value = 'error'
  }
}

async function onError(_event: FormErrorEvent) {
  console.error(_event)
  state.value = 'error'
}

const uiButton = {
  base: 'h-[50px]',
  rounded: 'rounded-lg',
  variant: {
    solid: 'shadow-none bg-forgd-primary-900 hover:bg-forgd-primary-900 disabled:bg-forgd-neutral-600/30 disabled:border disabled:border-forgd-neutral-600 disabled:text-forgd-neutral-600',
  },
}

const uiInput = {
  base: 'h-[50px]',
  rounded: 'rounded-lg',
  color: {
    white: {
      outline: 'shadow-none ring-forgd-bgd-600 disabled:bg-forgd-bgd-200',
    },
  },
  padding: {
    sm: 'px-4',
  },
}

const uiInputRight = {
  base: 'h-[50px]',
  rounded: 'rounded-none rounded-r-lg',
  color: {
    white: {
      outline: 'shadow-none ring-0 border border-forgd-bgd-600 focus:border-forgd-bgd-600 disabled:bg-forgd-bgd-200 ring-0 focus:ring-0',
    },
  },
  padding: {
    sm: 'px-4',
  },
}

const uiInputRightError = {
  base: 'h-[50px]',
  rounded: 'rounded-none rounded-r-lg',
  color: {
    white: {
      outline: 'shadow-none ring-0 border border-forgd-red-600 focus:border-forgd-bgd-600 disabled:bg-forgd-bgd-200 ring-0 focus:ring-0',
    },
  },
  padding: {
    sm: 'px-4',
  },
}

const uiSelectLeft = {
  base: 'h-[50px]',
  color: {
    white: {
      outline: 'shadow-none ring-0 border-l border-t border-b border-forgd-bgd-600 focus:border-forgd-bgd-600 disabled:bg-forgd-bgd-200 ring-0 focus:ring-0',
    },
  },
  padding: {
    sm: 'px-4',
  },
  rounded: 'rounded-none rounded-l-lg',
}

const uiSelectMenu = {
  rounded: 'rounded-lg',
  shadow: 'shadow-none',
}
</script>

<template>
  <UCard>
    <div class="p-5 space-y-5">
      <div class="font-semibold">
        Let’s confirm your account details
      </div>
      <UForm
        :validate="validate"
        :schema="schema"
        :state="form"
        class="space-y-5"
        :validate-on="validateOn"
        @submit="onSubmit"
        @error="onError"
      >
        <div class="grid grid-cols-2 gap-5">
          <UFormGroup label="First name" name="firstName">
            <UInput v-model="form.firstName" :ui="uiInput" />
            <template #error />
          </UFormGroup>
          <UFormGroup label="Last name" name="lastName">
            <UInput v-model="form.lastName" :ui="uiInput" />
            <template #error />
          </UFormGroup>
        </div>

        <div>
          <label class="text-gray-700">
            Preferred way of contact
          </label>
          <div class="mt-1 flex gap-0">
            <USelectMenu
              v-model="form.contactType"
              :options="contactTypes"
              value-attribute="value"
              option-attribute="label"
              :ui="uiSelectLeft"
              :ui-menu="uiSelectMenu"
            />
            <UFormGroup class="flex-grow">
              <UInput
                v-model="form.contact"
                :ui="contactError ? uiInputRightError : uiInputRight"
                @blur="validateContact"
              />
            </UFormGroup>
          </div>
        </div>

        <UFormGroup
          label="What is the name of your company/organization"
          name="companyName"
        >
          <UInput v-model="form.organizationName" :ui="uiInput" />
          <template #error />
        </UFormGroup>

        <UFormGroup
          label="What is your position in the company/organization"
          name="companyPosition"
        >
          <UInput v-model="form.position" :ui="uiInput" />
          <template #error />
        </UFormGroup>

        <UButton block :ui="uiButton" type="submit" :disabled="!canSubmit">
          Continue
          <template #trailing>
            <UIcon name="i-heroicons-arrow-right" class="w-4 h-4" />
          </template>
        </UButton>
      </UForm>
    </div>
  </UCard>
</template>
