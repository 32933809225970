import { type Ref, type Slots, unref } from 'vue'
import type { ComponentUi } from '~/utils/theme'

// ---------------------------------------------------------------------------------------------------------------------
// props
// ---------------------------------------------------------------------------------------------------------------------

// Constraint for components with $props
type ComponentWithProps = new (...args: any[]) => ComponentPublicInstance & { $props: Record<string, any> }

// Generic type to extract props from any component
export type PropsOf<T extends ComponentWithProps> = InstanceType<T>['$props']

type Mutable<T> = {
  -readonly [P in keyof T]: T[P];
}

/**
 * Type-safe function to build a props object for the base component
 *
 * @param callback    A callback with the signature
 */
export function makeProps<Base extends ComponentWithProps>(
  callback: (output: Partial<Mutable<PropsOf<Base>>>) => Partial<PropsOf<Base>>,
) {
  return callback({})
}

// ---------------------------------------------------------------------------------------------------------------------
// component ui props
// ---------------------------------------------------------------------------------------------------------------------

/**
 * Helper function to type-hint NuxtUI `ui` props options
 *
 * @param name      The component name
 * @param options   The component options
 *
 * @usage
 *
 *    const ui = defineUI('popover', { ... })
 */
export function defineUi<K extends keyof ComponentUi>(name: K, options: ComponentUi[K]): ComponentUi[K] {
  // trim and format passed options
  function clean(target: Record<string, any>): void {
    const keys = Object.keys(target)
    for (const key of keys) {
      const value = target[key]
      if (value && typeof value === 'object') {
        clean(value)
      }
      else if (typeof target[key] === 'string') {
        target[key] = value.trim().replace(/\s+/g, '\n')
      }
    }
  }

  // clean spaces
  clean(options as any)

  // return
  return options
}

// ---------------------------------------------------------------------------------------------------------------------
// sizes
// ---------------------------------------------------------------------------------------------------------------------

/**
 * Utility function to return a hash of { size: prop-value } config
 *
 * @param sizes
 * @param prop
 * @param values
 *
 * @usage
 *
 *    getSized(['sm', 'md', 'lg'] as const, 'text', 1, 2, 3)
 *
 *    const sizes = {
 *        sm: 'text-1',
 *        md: 'text-2',
 *        lg: 'text-3',
 *    }
 */
export function getSized<
  Sizes extends string[],
  Config extends { [K in Sizes[number]]: string },
>(
  sizes: [...Sizes],
  prop: string,
  ...values: number[]
): Config {
  return sizes.reduce((output, size, index: number) => {
    const value = index < values.length
      ? values[index]
      : values.at(-1)
    ;(output as any)[size] = `${prop}-${value}`
    return output
  }, {} as Config)
}

// ---------------------------------------------------------------------------------------------------------------------
// classes
// ---------------------------------------------------------------------------------------------------------------------

type ClassValue = string | { [key: string]: any } | ClassValue[]
type MaybeRef<T> = T | Ref<T>
type ClassOption = MaybeRef<ClassValue>

export function getClasses(...options: ClassOption[]): string {
  // Use a Set to store unique classes
  const classSet = new Set<string>()

  function addClasses(option: ClassValue) {
    if (typeof option === 'string') {
      option.split(/\s+/).forEach(cls => cls && classSet.add(cls.trim()))
    }
    else if (Array.isArray(option)) {
      option.forEach(addClasses)
    }
    else if (typeof option === 'object' && option !== null) {
      Object.entries(option).forEach(([key, value]) => {
        if (unref(value)) {
          classSet.add(key.trim())
        }
      })
    }
  }

  // add options
  options.forEach((option) => {
    const unwrapped = unref(option)
    addClasses(unwrapped)
  })

  // Convert Set to array and join with spaces
  return Array.from(classSet).join(' ')
}

// ---------------------------------------------------------------------------------------------------------------------
// event handlers
// ---------------------------------------------------------------------------------------------------------------------

interface EventHandlers {
  [key: string]: (...args: any[]) => void
}

type EmitFn = (name: string, ...args: any[]) => void

export function getHandlers(eventNames: string[], emit: EmitFn): EventHandlers {
  return eventNames.reduce<EventHandlers>((handlers, eventName) => {
    const handlerName = `on${eventName.charAt(0).toUpperCase()}${eventName.slice(1)}`
    handlers[handlerName] = (...args: any[]) => emit(eventName, ...args)
    return handlers
  }, {})
}

// ---------------------------------------------------------------------------------------------------------------------
// content
// ---------------------------------------------------------------------------------------------------------------------

export function getContent<P extends Record<string, any>>(slots: Slots, props: P, name: keyof P) {
  const ref = toRef(props, name)
  return computed(() => slots.default
    ? slots.default()
    : ref.value)
}

// ---------------------------------------------------------------------------------------------------------------------
// temp
// ---------------------------------------------------------------------------------------------------------------------

/*
import type { AllowedComponentProps, ComponentCustomProps, ComponentPublicInstance, VNodeProps } from 'vue'
import { UInput } from '#components'

type ExtractComponentProps<T> = T extends new () => ComponentPublicInstance<infer P>
  ? Omit<P, keyof VNodeProps | keyof AllowedComponentProps | keyof ComponentCustomProps>
  : never

type Props = ExtractComponentProps<typeof UInput>

function extendComponent<Base extends any>(
  base: Base,
  props: ExtractComponentProps<Base>,
) {

}
*/
