<script setup lang="ts">
import OnboardingV2OptionSelector from './OnboardingV2OptionSelector.vue'

defineProps<{
  options: Option[]
}>()

const model = defineModel<Option>()

const onboarding = useOnboardingV2()

interface Option {
  id: number
  label: string
  value: boolean
}

function onClick(value: Option) {
  model.value = value

  onboarding.next()
}
</script>

<template>
  <div class="flex flex-col gap-4">
    <OnboardingV2OptionSelector
      v-for="option in options"
      :key="option.label"
      @click="onClick(option)"
    >
      {{ option.label }}
    </OnboardingV2OptionSelector>
  </div>
</template>
