<script setup lang="ts">
const colorMode = useColorMode()
colorMode.preference = 'light'
</script>

<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    
  </div>
</template>

<style>
.balanced {
  text-wrap: balance
}

.balanced a {
  @apply text-forgd-primary-300 font-semibold;
}
</style>
