import { initContract } from '@ts-rest/core'
import { PostCalculatorEmissionBody, PostCalculatorEmissionsResponse } from '../types'

const c = initContract()

export const tokenDesignerCalculatorContract = c.router({
  postCalculatorEmissions: {
    method: 'POST',
    path: `/token-designer/calculator/emissions`,
    body: PostCalculatorEmissionBody,
    responses: {
      200: PostCalculatorEmissionsResponse,
    },
    summary: 'Get token designer emissions',
  },
})

export type TokenDesignerCalculatorContractType = typeof tokenDesignerCalculatorContract
