import { z } from 'zod'

const urlOrFallback = (url: string) => {
  try {
    new URL(url)
    return url
  } catch {
    return 'https://assets.coingecko.com/coins/images/4128/large/solana.png?16965'
  }
}

const CryptoInfo = z.object({
  id: z.string(),
  symbol: z.string(),
  name: z.string(),
  image: z.string().transform(urlOrFallback),
  current_price: z.number(),
  market_cap: z.number(),
  market_cap_rank: z.number(),
  fully_diluted_valuation: z.number().nullable(),
  total_volume: z.number(),
  high_24h: z.number(),
  low_24h: z.number(),
  price_change_24h: z.number(),
  price_change_percentage_24h: z.number(),
  market_cap_change_24h: z.number(),
  market_cap_change_percentage_24h: z.number(),
  circulating_supply: z.number(),
  total_supply: z.number().nullable(),
  max_supply: z.number().nullable(),
  ath: z.number(),
  ath_change_percentage: z.number(),
  ath_date: z.string().refine(
    (value) => {
      const date = new Date(value)
      return !isNaN(date.getTime())
    },
    {
      message: 'Invalid date format',
    },
  ),
  atl: z.number(),
  atl_change_percentage: z.number(),
  atl_date: z.string().refine(
    (value) => {
      const date = new Date(value)
      return !isNaN(date.getTime())
    },
    {
      message: 'Invalid date format',
    },
  ),
  roi: z.union([z.null(), z.any()]), // Replace z.any() with the appropriate type if known
  last_updated: z.string().refine(
    (value) => {
      const date = new Date(value)
      return !isNaN(date.getTime())
    },
    {
      message: 'Invalid date format',
    },
  ),
})

const PartialCryptoInfo = CryptoInfo.pick({
  id: true,
  symbol: true,
  name: true,
  image: true,
})

export const SearchCoinsResponseBody = z.object({
  coins: z.array(PartialCryptoInfo),
})
