<script setup lang="ts">
import type { TokenDetails } from '#account/components/onboarding/v2/OnboardingV2TokenDetails.vue'
import type { SelectCoin } from '#account/components/onboarding/v2/OnboardingV2TokenSelect.vue'
import type { CoinData } from '~/server/routes/coingecko/coins/[id].get'

const onboarding = useOnboardingV2()
const token = ref<SelectCoin>()
const selectedTokenDetails = ref<TokenDetails>()
const notFoundTokenDetails = ref<TokenDetails>()

watch(token, fetchTokenDetails)
async function fetchTokenDetails() {
  if (!token.value) {
    return
  }

  const res = await $fetch<CoinData>(`/coingecko/coins/${token.value.id}`)

  if (res) {
    selectedTokenDetails.value = {
      baseLayerId: res.asset_platform_id,
      coingeckoId: res.id,
      image: res.image.large,
      name: res.name,
      ticker: res.symbol,
      link: res.links.homepage[0],
      tgeDate: res.genesis_date,
    }
  }
}
</script>

<template>
  <OnboardingV2TokenSelect v-if="onboarding.tokenFindState !== 'token-not-listed'" v-model="token" />
  <OnboardingV2TokenDetails v-if="onboarding.tokenFindState === 'token-selected' && selectedTokenDetails" v-model="selectedTokenDetails" />
  <OnboardingV2TokenDetails v-else-if="onboarding.tokenFindState === 'token-not-found' || onboarding.tokenFindState === 'token-not-listed'" v-model="notFoundTokenDetails" />
</template>
