import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import {
  SendInvitationRequestBody,
  ResendInvitationRequestBody,
  AcceptInvitationRequestBody,
  DeleteMemberBody,
  GetMembersResponseBody,
} from '../types/membership'

const c = initContract()

export const membershipContract = c.router({
  send: {
    method: 'POST',
    path: `/membership/send`,
    responses: {
      204: z.undefined(),
    },
    body: SendInvitationRequestBody,
    summary: 'Send membership invitation to a list of members',
  },
  resend: {
    method: 'POST',
    path: `/membership/resend`,
    responses: {
      204: z.undefined(),
    },
    body: ResendInvitationRequestBody,
    summary: 'Resend membership invitation',
  },
  accept: {
    method: 'POST',
    path: `/membership/accept`,
    responses: {
      204: z.undefined(),
    },
    body: AcceptInvitationRequestBody,
    summary: 'Accept membership invitation',
  },
  delete: {
    method: 'DELETE',
    path: `/membership/delete`,
    responses: {
      204: z.undefined(),
    },
    body: DeleteMemberBody,
    summary: 'Delete member',
  },
  getMembers: {
    method: 'GET',
    path: `/membership/:projectId/get-members`,
    pathParams: z.object({
      projectId: z.string().uuid(),
    }),
    responses: {
      200: GetMembersResponseBody,
    },
    summary: 'Get a list of project members',
  },
})

export type MembershipContractType = typeof membershipContract
