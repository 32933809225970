<script lang="ts" setup>
import { twMerge } from 'tailwind-merge'

const props = defineProps<{
  variant?: 'inline'
  hint?: string
  ui?: {
    container?: string
  }
}>()

const ui = computed(() => {
  if (props.variant === 'inline') {
    return { container: twMerge('max-w-[450px] flex-grow', props.ui?.container), wrapper: 'flex items-center justify-between w-full gap-3', label: { base: 'font-semibold text-sm whitespace-nowrap' }, hint: 'w-5 h-5 ml-1' }
  }

  return { label: { base: 'font-semibold  text-sm text-forgd-primary-900 mb-2' }, description: 'text-gray-600 text-sm mb-3' }
})
</script>

<template>
  <UFormGroup data-el="TdFormGroup" size="xl" v-bind="$attrs" :ui="ui">
    <template v-if="hint" #hint>
      <UiTooltip :text="hint" />
    </template>
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </UFormGroup>
</template>
