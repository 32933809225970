import { z } from 'zod'
import {
  DemandDetailsSchema,
  EstimatedPerformanceSummarySchema,
  LaunchAndGrowthKPIsResponse,
  MonthlySupplyDemandDelta,
} from '../token-designer'

export const MetaSchema = z.object({
  xAxis: z.string(),
  labels: z.record(z.string(), z.any()),
})

export const UnlockProgressGroupResponse = z.object({
  group_name: z.string(),
  total_allocation: z.number(),
  unlocked_percentage: z.number(),
  allocation_percentage: z.number(),
  tokens_unlocked: z.number(),
  tokens_locked: z.number(),
})

export const UnlockProgressResponse = z.object({
  groups_table: z.array(UnlockProgressGroupResponse),
  cumulative_monthly_unlock: z.object({
    data: z.array(
      z.record(z.union([z.number(), z.string()])).and(
        z.object({
          date: z.string(),
          total_tokens_unlocked: z.number(),
          percentage_of_total_unlocked: z.number(),
        }),
      ),
    ),
    meta: MetaSchema,
  }),
  current_unlock_progress: z.number(),
  total_tokens: z.number(),
  total_tokens_locked: z.number(),
  total_value_locked: z.number(),
  total_tokens_unlocked: z.number(),
  total_value_unlocked: z.number(),
  days_left: z.number(),
  months_left: z.number(),
})

export const ChartDataItemSchema = z.record(z.union([z.string(), z.number()]))

export const UpcomingTokenUnlocksResponse = z.object({
  total_tokens_to_be_unlocked: z.number(),
  percent_of_total_supply: z.number(),
  percent_of_circulating_supply: z.number(),
  total_value_to_be_unlocked: z.number(),
  unlocked_by_group: z.object({
    data: z.array(ChartDataItemSchema),
    meta: MetaSchema,
  }),
  cumulative_value_unlocked_groups: z.object({
    data: z.array(ChartDataItemSchema),
    meta: MetaSchema,
  }),
})

export const TotalTokensUnlockedResponse = z.object({
  total_tokens_unlocked: z.number(),
})

export const SupplyDemandTypeResponse = z.object({
  message: z.string(),
  result: z.array(MonthlySupplyDemandDelta),
})

export const CumulativeEmissionResponse = z
  .object({
    month: z.number(),
  })
  .catchall(z.number())

export const CalculatorEmissionResponse = z.object({
  averageAnnualInflation: z.number(),
  circulatingSupplyAtTGE: z.number(),
  cumulativeEmission: z.array(CumulativeEmissionResponse),
  percentageUnlockedAtTGE: z.number(),
  totalEmissionDuration: z.number(),
  total_groups: z.number(),
})

export const CalculatorEmissionsResponse = z.object({
  emissions: CalculatorEmissionResponse,
  message: z.string().optional(),
})

export const GetLaunchAndGrowthKpisResponse = z.object({
  result: LaunchAndGrowthKPIsResponse,
})

export const DailyPriceSchema = z.object({
  'Buy Pressure (Qty)': z.number(),
  'Buy Pressure (US Dollar Value) - Adjusted by Elasticity': z.number(),
  'Buy Pressure (US Dollar Value) - Unadjusted by Elasticity': z.number(),
  'Buy Pressure (US Dollars)': z.number(),
  'Days post-TGE': z.number(),
  'Demand Elasticity Adjustment': z.number(),
  'Ending Fully Dilluted Valuation': z.number(),
  'Ending Market Capitalization': z.number(),
  'Ending Price': z.number(),
  'Impact on Previous Price': z.number(),
  'Liquidity as a % of Market Capitalization': z.number(),
  'Months post-TGE': z.number(),
  'Net Buy / Sell Pressure (US Dollar Value)': z.number(),
  'Price % Change vs. Post-TGE Pop': z.number(),
  'Price % Change vs. TGE': z.number(),
  'Sell Pressure (Qty)': z.number(),
  'Sell Pressure (US Dollar Value)': z.number(),
  'Slippage Induced by Trade Simulation': z.number(),
  'Starting Fully Dilluted Valuation': z.number(),
  'Starting Market Capitalization': z.number(),
  'Starting Price': z.number(),
  'US Dollar Value of Bids & Offers per 1% relative to Midpoint': z.number(),
})

export const MonthlySupplyGrowthSchema = z.object({
  mon_supply_growth: z.number(),
  month: z.number(),
})

export const MonthlyCumulativeSellPressureSchema = z.record(z.number())

export const MonthlyDollarDemandSchema = z.object({
  demand_drivers: z.record(z.number()),
  months_post_tge: z.number(),
})

export const DailyPriceResponse = z.object({
  daily_price: z.array(DailyPriceSchema),
  demand_details: DemandDetailsSchema,
  message: z.string().optional(),
  monthly_circulating_supply_growth: z.array(MonthlySupplyGrowthSchema),
  monthly_cumulative_sell_pressure: z.array(MonthlyCumulativeSellPressureSchema),
  monthly_dollar_demand: z.array(MonthlyDollarDemandSchema),
  estimated_performance_summary: z.array(EstimatedPerformanceSummarySchema),
})

export type UnlockProgress = z.infer<typeof UnlockProgressResponse>
export type UpcomingTokenUnlocks = z.infer<typeof UpcomingTokenUnlocksResponse>
export type SupplyDemandType = z.infer<typeof SupplyDemandTypeResponse>
export type CalculatorEmissionResponseType = z.infer<typeof CalculatorEmissionResponse>
export type CalculatorEmissionsResponseType = z.infer<typeof CalculatorEmissionsResponse>
export type MonthlySupplyGrowth = z.infer<typeof MonthlySupplyGrowthSchema>
export type MonthlyCumulativeSellPressure = z.infer<typeof MonthlyCumulativeSellPressureSchema>
export type DailyPriceResponseType = z.infer<typeof DailyPriceResponse>
export type DailyPrice = z.infer<typeof DailyPriceSchema>
