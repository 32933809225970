import { initContract } from '@ts-rest/core'
import { ProjectPathParam } from '../types/common'
import {
  GetLiquidityBreakdownsQuery,
  GetLiquidityBreakdownsResult,
  GetMarketplaceLiquidityQuery,
  GetMarketplaceLiquidityResult,
  GetVolumeBreakdownsQuery,
  GetVolumeBreakdownsResult,
  GetPerformanceInsightsQuery,
  GetPerformanceInsightsResult,
  GetKPIDataQuery,
  GetKPIDataResult,
  GetMMComparisonQuery,
  GetMMComparisonResult,
} from '../types'

const c = initContract()

const basePath = 'market-maker-monitoring'

export const marketMakerMonitoringContract = c.router({
  getLiquidityBreakdowns: {
    method: 'GET',
    path: `/${basePath}/liquidity-breakdowns/:projectId`,
    query: GetLiquidityBreakdownsQuery,
    pathParams: ProjectPathParam,
    responses: {
      200: GetLiquidityBreakdownsResult,
    },
    summary: 'Get liquidity breakdowns by project id',
  },
  getVolumeBreakdowns: {
    method: 'GET',
    path: `/${basePath}/volume-breakdowns/:projectId`,
    query: GetVolumeBreakdownsQuery,
    pathParams: ProjectPathParam,
    responses: {
      200: GetVolumeBreakdownsResult,
    },
    summary: 'Get volume breakdowns by project id',
  },
  getMarketplaceLiquidity: {
    method: 'GET',
    path: `/${basePath}/marketplace-liquidity/:projectId`,
    query: GetMarketplaceLiquidityQuery,
    pathParams: ProjectPathParam,
    responses: {
      200: GetMarketplaceLiquidityResult,
    },
    summary: 'Get marketplace liquidity by project id',
  },
  getPerformanceInsights: {
    method: 'GET',
    path: `/${basePath}/performance-insights/:projectId`,
    query: GetPerformanceInsightsQuery,
    pathParams: ProjectPathParam,
    responses: {
      200: GetPerformanceInsightsResult,
    },
    summary: 'Get performance insights by project id',
  },
  getKPIData: {
    method: 'GET',
    path: `/${basePath}/kpi-data/:projectId`,
    query: GetKPIDataQuery,
    pathParams: ProjectPathParam,
    responses: {
      200: GetKPIDataResult,
    },
    summary: 'Get KPI data by project id',
  },
  getMMComparison: {
    method: 'GET',
    path: `/${basePath}/mm-comparison/:projectId`,
    query: GetMMComparisonQuery,
    pathParams: ProjectPathParam,
    responses: {
      200: GetMMComparisonResult,
    },
    summary: 'Get makert maker comparison data by project id',
  },
})

export type MarketMakerMonitoringContractType = typeof marketMakerMonitoringContract
