import { z } from 'zod'
import { stringToIntNumber } from '../../helper'
import { EngagementOptionArray } from '@forgd/supabase'

export const GetMMComparisonQuery = z.object({
  date: z.string().date(),
  marketMaker: z.string().uuid().optional().nullable(),
  orderBook: z.string().optional().nullable(),
  exchange: z.string().optional().nullable(),
  daysLookback: z
    .string()
    .optional()
    .nullable()
    .transform(stringToIntNumber)
    .refine((val) => val == null || val > 0, {
      message: 'daysLookback must be a positive integer',
    }),
})

export type GetMMComparisonQueryType = z.infer<typeof GetMMComparisonQuery>

export const GetMMComparisonResult = z.array(
  z.object({
    avgFillVolume: z.number().optional().nullable(),
    avgDepth: z.number().optional().nullable(),
    avgSpread: z.number().optional().nullable(),
    avgVolumeBasedLoanUtil: z.number().optional().nullable(),
    avgLiquidityBasedLoanUtil: z.number().optional().nullable(),
    mmId: z.string().uuid(),
    mmType: z.enum(EngagementOptionArray),
    mmName: z.string(),
    mmImageUrl: z.string(),
    totalDaysTracked: z.number().int(),
  }),
)

export type GetMMComparisonResultType = z.infer<typeof GetMMComparisonResult>
