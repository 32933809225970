import { z } from 'zod'
import { MetaSchema } from '../calculator'
import { PriceDataSchema, TokenInfoResult } from '../token-unlock'
import {
  ContactTypeEnum,
  EngagementOptionArray,
  EngagementOptionEnum,
  ExchangeTypeArray,
  MarketMakerQuoteKpiMetricArray,
  MarketMakerQuoteLoanCustodyArray,
  MarketMakerQuoteTranchePriceMethodologyArray,
  MarketMakerRequestStatusArray,
  OptionsStyleArray,
} from '@forgd/supabase'
import { MemberContactType } from '../membership'

export const MMRFQTranchePriceFlagArray = ['discount', 'par', 'premium'] as const

export enum MMRFQTranchePriceFlagEnum {
  Discount = 'discount',
  Par = 'par',
  Premium = 'premium',
}

export interface MarketMakerRFQOption {
  value: string
  label: string
  description?: string
  loanRequirements?: string[]
}

export const OptionResult = z.object({
  value: z.string(),
  label: z.string(),
})

export type Investor = z.infer<typeof OptionResult>

export const InvestorsResult = z.array(OptionResult)

export const RFQProjectDetailsResult = z.object({
  ticker: z.string().nullable(),
  tokenListed: z.boolean().nullable().default(false),
  link: z.string().nullable(),
  coingeckoId: z.string().nullable(),
  isTdPublished: z.boolean(),
  hasRFQ: z.boolean(),
})
export type RFQProjectDetails = z.infer<typeof RFQProjectDetailsResult>

export const RFQTokenDesignerDetailsResult = z.object({
  tgeDate: z.string().date().nullable(),
  maxTokenSupply: z.number().nullable(),
  fdvAtTGE: z.number().nullable(),
  tokenPriceAtTGE: z.number().nullable(),
  percentageUnlockedAtTGE: z.number(),
  circulatingSupply: z.number().nullable(),
  marketCapAtTGE: z.number().nullable(),
})

export type RFQTokenDesignerDetails = z.infer<typeof RFQTokenDesignerDetailsResult>

export const RFQTokenMarketDetailsResult = TokenInfoResult.merge(
  z.object({
    price: z.number(),
    priceChange24h: z.number(),
    marketChartPrice24h: z
      .object({
        data: z.array(PriceDataSchema),
        meta: MetaSchema,
      })
      .nullable(),
  }),
)

export type RFQTokenMarketDetails = z.infer<typeof RFQTokenMarketDetailsResult>

export const RFQStatusItem = z.object({
  status: z.enum(MarketMakerRequestStatusArray),
  date: z.string().date().nullable(),
})

export const RFQDetailsResult = z.object({
  project: RFQProjectDetailsResult,
  tokenDesignerDetails: RFQTokenDesignerDetailsResult.optional(),
  tokenMarketDetails: RFQTokenMarketDetailsResult.optional(),
  statuses: z.array(RFQStatusItem),
})

export type RFQDetails = z.infer<typeof RFQDetailsResult>

export const ExchangeResult = OptionResult.extend({
  url: z.string().nullable().optional(),
  type: z.enum(ExchangeTypeArray),
})

export type Exchange = z.infer<typeof ExchangeResult>

export const ExchangesResult = z.array(ExchangeResult)

export const MarketMakerResult = z.object({
  name: z.string(),
  description: z.string().nullable(),
  imageUrl: z.string().nullable(),
  engagementOptions: z.array(z.enum(EngagementOptionArray)),
  supportedCEX: ExchangesResult,
  supportedDEX: ExchangesResult,
  services: z.array(z.string()),
})

export type MarketMaker = z.infer<typeof MarketMakerResult>

export const MarketMakersResult = z.array(MarketMakerResult)

export const MarketMakerRFQBody = z.object({
  // step 1
  country: z.string().min(2, 'Country is required'),
  isTokenIssuedInUSTerritory: z.boolean().nullable().optional(),
  hasLegalOpinionOnUtilityStatus: z.boolean({
    message: 'Legal opinion is required',
  }),
  externalCapital: z.string().min(1, 'The Amount of external capital is required'),
  investors: z.array(z.string().uuid()).optional(),
  // step 2
  motivations: z.array(z.string()).min(1),
  otherCEX: z.array(z.string()),
  otherDEX: z.array(z.string()),
  exchangesCEX: z.array(z.string()),
  exchangesDEX: z.array(z.string()),
  // step 3
  marketMakersQty: z.number().min(1).max(4),
  contactType: z.nativeEnum(MemberContactType),
  contact: z.string().min(1, 'Contact is required'),
  engagementOptions: z.array(z.enum(EngagementOptionArray)).min(1),
  tokenSupplyPercentageToAllocate: z.string().min(1),
  stableCoinBudgetToAllocate: z.string().min(1),
  otherMarketMakers: z.array(z.string()),

  calendarLink: z.string().optional(),
})

export type MarketMakerRFQ = z.infer<typeof MarketMakerRFQBody>

export type MMRFQEmailRequest = {
  projectId: string
  projectName: string
  projectTicker: string
  projectLink: string
  tokenListed: boolean
  tgeDate: string
  coingeckoId: string | null
  country: string
  isUSA: boolean
  isUtility: boolean
  externalCapital: string
  investors: string[]
  exchangesCEX: string[]
  exchangesDEX: string[]
  motivations: string[]
  marketMakersQty: number
  engagementOptions: EngagementOptionEnum[]
  tokenSupplyPercentageToAllocate: string
  stableCoinBudgetToAllocate: string
  otherMarketMakers: string[]
  contactType: ContactTypeEnum
  contact: string
  calendarLink: string
}

export type MMRFQRoundInReviewEmailRequest = {
  round: number
  projectName: string
  projectTicker: string | null
  mmQty: number
  quotesQty: number
  dateToReply: Date
}

export type MMRFQAcceptQuotesEmailRequest = {
  projectName: string
  round: number
  selection: string[]
}

export type MMRFQRequoteQuotesEmailRequest = {
  projectName: string
  selection: string[]
  date: Date
}

const QuoteKPISchema = z.object({
  quoteId: z.string(),
  metric: z.string(),
  valueTier1Exchanges: z.number(),
  valueTier2Exchanges: z.number(),
  valueTier3Exchanges: z.number(),
  minValue: z.number(),
  maxValue: z.number(),
  minMaxUnit: z.string(),
})

const QuoteDMMSchema = z.object({
  quoteId: z.string(),
  onboardingFeeUsd: z.number(),
  unlimitedExchangeCoverage: z.boolean(),
  unlimitedExchangeCoverageMonthlyFeeUsd: z.number(),
  singleExchangeCoverageMonthlyFeeUsd: z.number(),
  minContractTermMonths: z.number(),
  profitSharePercentage: z.number(),
  loanCustody: z.enum(['market_maker', 'project']),
})

const QuoteTrancheSchema = z.object({
  quoteId: z.string(),
  trancheNumber: z.number(),
  tokenSupplyPercentage: z.number(),
  priceValue: z.number(),
  priceMethodology: z.enum(['fixed', 'dynamic']),
  twapThresholdDays: z.number().nullable(),
  timePeriod: z.number(),
})

export const QuoteSchema = z.object({
  id: z.string(),
  rfqId: z.string(),
  projectId: z.string(),
  marketMakerId: z.string(),
  round: z.number(),
  proposal: z.number(),
  engagementType: z.enum(EngagementOptionArray),
  loanTokenSupplyPercentage: z.number(),
  loanStablecoinQuantity: z.number(),
  loanOptionsStyle: z.enum(['american', 'european']).nullable(),
  loanTenorMonths: z.number().nullable(),
  loanInterestRatePercentage: z.number().nullable(),
  kpiOrderBookDominanceThreshold: z.number(),
  status: z.string(),
  selectedForRequote: z.boolean(),
  createdAt: z.string(),
  dmm: z.union([QuoteDMMSchema.nullable(), z.array(QuoteDMMSchema).optional()]),
  kpis: z.array(QuoteKPISchema),
  tranches: z.array(QuoteTrancheSchema).optional(),
})

export type Quote = z.infer<typeof QuoteSchema>

export const ViewSchemaResponse = z.object({
  value: z.number(),
  unit: z.string(),
})

export const MetricSchemaResponse = z.object({
  metric: z.enum(MarketMakerQuoteKpiMetricArray),
  views: z.object({
    percent: ViewSchemaResponse,
    usd: ViewSchemaResponse,
  }),
})

export const TierSchemaResponse = z.object({
  tier: z.string(),
  data: z.array(MetricSchemaResponse),
})

export type TierSchema = z.infer<typeof TierSchemaResponse>
export const RFQQuotesSummary = z.object({
  quotesReceptionDate: z.string().date().optional(),
  quotesCount: z.number(),
  engagements: z.object({
    [EngagementOptionEnum.LoanCallOption]: z.object({
      quotesCount: z.number(),
      avgTokenLoanSize: z.number(),
      avgTokenLoanSupplyPercentage: z.number(),
      europeanOptionsCount: z.number(),
      americanOptionsCount: z.number(),
      avgLoanTenorMonths: z.number(),
      avgTokenLoanSizeUsd: z.number(),
      avgOptionPremiumPerQuote: z.number(),
    }),
    [EngagementOptionEnum.RetainerWorkingCapital]: z.object({
      quotesCount: z.number(),
      avgTokenLoanSize: z.number(),
      avgTokenLoanSupplyPercentage: z.number(),
      avgStablecoinLoanSizeUsd: z.number(),
      avgTokenLoanSizeUsd: z.number(),
      avgOnboardingFeeUsd: z.number(),
      avgMonthlyFeeUsd: z.number(),
      avgProfitSharePercent: z.number(),
    }),
  }),
})

export const RFQRoundDetailsResponse = z.object({
  rfq: z.object({
    marketMakersQty: z.number(),
    engagementOptions: z.array(z.enum(EngagementOptionArray)).nullable().default([]),
    tokenSupplyPercentageToAllocate: z.string(),
    stableCoinBudgetToAllocate: z.string(),
    quotes: z.array(QuoteSchema),
  }),
  quotesSummary: RFQQuotesSummary,
  kpisSummary: z.array(TierSchemaResponse),
})

export type RFQRoundDetails = z.infer<typeof RFQRoundDetailsResponse>

export const LoanCallOptionTranche = z.object({
  trancheNumber: z.number().int().min(0).finite(),
  tokenSupplyPercentage: z.number(),
  priceMethodology: z.enum(MarketMakerQuoteTranchePriceMethodologyArray),
  price: z.number(),
  priceFlag: z.enum(MMRFQTranchePriceFlagArray),
  twapThresholdDays: z.number().nullable(),
  timePeriod: z.number(),
})

export type LoanCallOptionTranche = z.infer<typeof LoanCallOptionTranche>

const BaseQuoteSchema = z.object({
  id: z.string().uuid(),
  engagementType: z.string(),
  marketMakerName: z.string(),
  marketMakerImageUrl: z.string().nullable(),
  proposal: z.number().int().min(1).finite(),
  loanTokenSupplyPercentage: z.number(),
  loanTokenQuantity: z.number(),
  loanTokenSizeUsd: z.number(),
  kpiOrderBookDominanceThreshold: z.number(),
  kpiLoanValueDepth200BpsUsd: z.number(),
  projectedLoanUtilizationPercentage: z.number(),
  kpis: z.array(TierSchemaResponse),
  marketMakerComment: z.string().nullable(),
})

export const LoanCallOptionQuoteResponse = BaseQuoteSchema.extend({
  loanOptionsStyle: z.enum(OptionsStyleArray),
  loanTenorMonths: z.number(),
  loanInterestRatePercentage: z.number().nullable(),
  kpiBidAskSpreadBps: z.number(),
  tranchesCount: z.number(),
  tranches: z.array(LoanCallOptionTranche),
})

export type LoanCallOptionQuote = z.infer<typeof LoanCallOptionQuoteResponse>

export const RetainerWorkingCapitalQuoteResponse = BaseQuoteSchema.extend({
  loanStablecoinSizeUsd: z.number(),
  loanCustody: z.enum(MarketMakerQuoteLoanCustodyArray),
  onboardingFeeUsd: z.number(),
  unlimitedExchangeCoverage: z.boolean(),
  monthlyFeeUsd: z.number(),
  minContractTermMonths: z.number(),
  profitSharePercentage: z.number(),
})

export type RetainerWorkingCapitalQuote = z.infer<typeof RetainerWorkingCapitalQuoteResponse>

export const QuotesByEngagementTypeResponse = z.object({
  [EngagementOptionEnum.LoanCallOption]: z.array(LoanCallOptionQuoteResponse),
  [EngagementOptionEnum.RetainerWorkingCapital]: z.array(RetainerWorkingCapitalQuoteResponse),
})

export type QuotesByEngagementType = z.infer<typeof QuotesByEngagementTypeResponse>

export const QuotesSelectionPreviewResponse = z.object({
  quotes: QuotesByEngagementTypeResponse,
  kpisSummary: z.array(TierSchemaResponse),
  quotesSummary: RFQQuotesSummary,
  selectedQuotesSummary: RFQQuotesSummary,
})

export type QuotesSelectionPreview = z.infer<typeof QuotesSelectionPreviewResponse>
