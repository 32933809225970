<template>
  <div
    class="border-t border-forgd-gray-300 py-4 px-6 flex justify-between gap-2 items-center"
  >
    <div v-if="false" class="flex space-x-2 items-center">
      <svg
        class="h-6 w-6 text-forgd-primary-900"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 8L10.89 13.26C11.2187 13.4793 11.6049 13.5963 12 13.5963C12.3951 13.5963 12.7813 13.4793 13.11 13.26L21 8M5 19H19C19.5304 19 20.0391 18.7893 20.4142 18.4142C20.7893 18.0391 21 17.5304 21 17V7C21 6.46957 20.7893 5.96086 20.4142 5.58579C20.0391 5.21071 19.5304 5 19 5H5C4.46957 5 3.96086 5.21071 3.58579 5.58579C3.21071 5.96086 3 6.46957 3 7V17C3 17.5304 3.21071 18.0391 3.58579 18.4142C3.96086 18.7893 4.46957 19 5 19Z"
          stroke="#041524"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <span class="text-xs font-normal flex-shrink-0">Join Our Newsletter</span>

      <button
        class="flex-shrink-0 rounded-full font-mono bg-forgd-bgd-300 border border-forgd-bgd-600 text-forgd-primary-900 font-medium text-xs flex item-center space-x-1 py-1 px-2"
      >
        <span>One-Click Subscription</span>
        <svg
          class="h-4 w-4"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.33333 3.3335L14 8.00016M14 8.00016L9.33333 12.6668M14 8.00016H2"
            stroke="#041524"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
    <div />

    <div class="flex items-center justify-between w-full">
      <p class="text-xs font-normal text-forgd-primary-900">
        &copy; {{ (new Date).getFullYear() }} Forgd. All rights reserved.
        <NuxtLink to="/terms-of-use/" class="font-semibold text-forgd-primary-300">
          Terms & Conditions
        </NuxtLink>
      </p>

      <div class="text-forgd-gray-500 text-xs">
        The content on this site is for informational purposes only and should not be construed as financial or legal advice.
      </div>
    </div>
  </div>
</template>
