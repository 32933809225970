<script setup lang="ts">
const props = defineProps<{
  image?: string
}>()

const emit = defineEmits(['update:image'])

const auth = useAuth()
const client = useClient()
const toast = useAppToast()

const image = ref(props.image || auth.project?.image || null)
watch(props, (newVal) => {
  image.value = newVal.image || auth.project?.image || null
})

const busy = ref(false)

const logo = ref()

interface UploadResponse {
  success: boolean
  result: {
    id: string
    variants: Array<string>
  }
  errors: Array<unknown>
}
const toastUploadError: Partial<Notification> = {
  id: 'upload-error',
  title: 'Image upload failed',
}

async function uploadImage() {
  if (!auth.project) {
    return
  }

  if (!logo.value.files.length) {
    return
  }
  const file = logo.value.files[0]
  // validate the size
  if (file.size > 5 * 1024 * 1024) {
    toast.error({
      ...toastUploadError,
      title: 'Image size too large',
      description: 'Please upload an image that is less than 5 MB in size.',
    })
    return
  }
  // validate the dimensions
  const imageElement = new Image()
  imageElement.src = URL.createObjectURL(file)
  await new Promise((resolve) => {
    imageElement.onload = resolve
  })
  if ((imageElement.width < 150 || imageElement.height < 150) && file.type !== 'image/svg+xml') {
    toast.error({
      ...toastUploadError,
      title: 'Image dimensions too small',
      description: 'Please upload an image that is at least 150 x 150 pixels in size.',
    })
    return
  }
  // make sure the logo is square
  if (imageElement.width !== imageElement.height) {
    toast.error({
      ...toastUploadError,
      title: 'Image Upload Failed: The image must be in a square format.',
      description: 'Please upload an image with equal width and height dimensions to proceed.',
    })
    return
  }

  const formData = new FormData()
  formData.append('file', file)

  busy.value = true

  const { success, result } = await $fetch('/image', {
    method: 'POST',
  })

  if (success) {
    const uploadResult = await $fetch<Promise<UploadResponse>>(
      result.uploadURL,
      {
        method: 'POST',
        body: formData,
      },
    )

    if (uploadResult.success) {
      toast.success({
        id: 'upload-success',
        title: 'Image upload successful',
        icon: 'i-heroicons-check',
      })

      await client.projects.updateProjectImage({
        params: {
          projectId: auth.project?.id,
        },
        body: {
          image: uploadResult.result.variants[0],
        },
      })

      image.value = uploadResult.result.variants[0]

      emit('update:image', uploadResult.result.variants[0])

      // forces projects to be refreshed
      await auth.refresh({ from: 'UiProjectImageUpload:uploadImage' })
    }
    else {
      toast.error(toastUploadError)
    }

    busy.value = false
  }
  else {
    toast.error(toastUploadError)
  }
}
</script>

<template>
  <div
    data-ui="UiProjectImageUpload"
    class="rounded-lg mt-5 border border-dashed border-forgd-bgd-600 p-5 text-center flex flex-col justify-center"
    v-bind="$attrs"
  >
    <input
      id="project-image"
      ref="logo"
      type="file"
      accept="image/jpeg,image/png,image/svg+xml"
      class="hidden"
      :disabled="busy"
      @input="uploadImage"
    >

    <div
      class="mx-auto rounded-full flex justify-center items-center"
    >
      <img
        v-if="image"
        :src="image"
        class="rounded-full w-[120px] h-[120px]"
      >
      <UIcon
        v-else
        name="i-heroicons-camera"
        class="w-8 h-8 text-forgd-neutral-700"
      />
    </div>

    <div class="mt-8">
      <label
        for="project-image"
        class="text-forgd-primary-900 border border-forgd-bgd-700 bg-forgd-bgd-100 rounded-lg px-16 py-2.5 text-sm font-semibold cursor-pointer"
        :class="[busy ? 'loader' : '']"
      >
        <slot name="label">
          Select image file
        </slot>
      </label>
    </div>

    <div class="text-forgd-bgd-700 text-sm font-normal mt-8">
      <p>Use .JPG or .PNG files. 5 MB max.</p>
      <p>Use a square image, not smaller than 150x150px.</p>
    </div>
  </div>
</template>
