import { z } from 'zod'
import { stringToIntNumber } from '../../helper'

export const GetPerformanceInsightsQuery = z.object({
  date: z.string().date(),
  marketMaker: z.string().uuid().optional().nullable(),
  exchange: z.string().optional().nullable(),
  orderBook: z.string().optional().nullable(),
  daysLookback: z
    .string()
    .optional()
    .nullable()
    .transform(stringToIntNumber)
    .refine((val) => val == null || val > 0, {
      message: 'daysLookback must be a positive integer',
    }),
})

export type GetPerformanceInsightsQueryType = z.infer<typeof GetPerformanceInsightsQuery>

export const GetPerformanceInsightsResult = z.array(
  z.object({
    makerTakerPercentage: z.number(),
    allMmBidAsk200bpsPercentage: z.number(),
    avgSpread: z.number(),
  }),
)

export type GetPerformanceInsightsResultType = z.infer<typeof GetPerformanceInsightsResult>
