<script setup lang="ts">
import { Toggle } from 'radix-vue'

defineProps<{
  label: string
}>()

const model = defineModel<boolean>()

const classes = computed(() => model.value
  ? 'bg-forgd-neutral-600/30 border-forgd-primary-300'
  : 'bg-forgd-neutral-100 border-forgd-neutral-700',
)
</script>

<template>
  <Toggle
    v-model:pressed="model"
    :aria-label="label"
  >
    <div class="relative h-full rounded-lg w-[365px] border p-5 text-left" :class="classes">
      <div class="absolute top-5 right-5">
        <UIcon v-if="model" name="i-heroicons-check" class="w-5 h-5 text-forgd-primary-300" />
      </div>
      <div>
        <slot name="icon" />
      </div>
      <div class="mt-5 text-base font-semibold text-forgd-primary-900">
        <slot name="label">
          {{ label }}
        </slot>
      </div>
      <div class="mt-5 text-sm text-forgd-gray-600 description">
        <slot />
      </div>
    </div>
  </Toggle>
</template>

<style scoped>
:deep(.description div) {
  @apply space-y-3;
}
:deep(.description ul) {
  @apply list-disc list-inside;
}
</style>
