import { z } from 'zod'
import { DistributionGroup, TokenEmissionPreview } from './modeling.types'

export const PostCalculatorEmissionBody = z.object({
  total_supply: z.number(),
  groups: z.array(DistributionGroup),
})
export type PostCalculatorEmissionBody = z.infer<typeof PostCalculatorEmissionBody>

export const PostCalculatorEmissionsResponse = z.object({
  emissions: TokenEmissionPreview.nullable(),
})
export type PostCalculatorEmissionsResponse = z.infer<typeof PostCalculatorEmissionsResponse>
