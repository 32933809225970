<template>
  <UiAlert>
    <p>
      By inviting a new team member to join your organization, you are agreeing to the Forgd&nbsp;
      <NuxtLink :to="webflowLink('/terms-of-use/')" class="text-primary font-semibold" target="_blank">
        Terms&nbsp;&&nbsp;Conditions
      </NuxtLink>and&nbsp;
      <NuxtLink :href="webflowLink('/privacy-policy/')" class="text-primary font-semibold" target="_blank">
        Privacy&nbsp;Policy.
      </NuxtLink>
    </p>
  </UiAlert>
</template>
