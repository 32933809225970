import { z } from 'zod'

export const GetMarketplaceLiquidityQuery = z.object({
  date: z.string().date(),
  exchange: z.string().optional().nullable(),
  orderBook: z.string().optional().nullable(),
})

export type GetMarketplaceLiquidityQueryType = z.infer<typeof GetMarketplaceLiquidityQuery>

export const GetMarketplaceLiquidityResult = z.array(
  z.object({
    timestamp: z.string(),
    price: z.number(),
    costToMoveDown: z.number(),
    costToMoveUp: z.number(),
    spread: z.number().nullable(),
  }),
)

export type GetMarketplaceLiquidityResultType = z.infer<typeof GetMarketplaceLiquidityResult>
