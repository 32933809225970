export default {
  app: () => import("/opt/render/project/src/apps/ui/layers/site/layouts/app.vue"),
  default: () => import("/opt/render/project/src/apps/ui/layers/site/layouts/default.vue"),
  landing: () => import("/opt/render/project/src/apps/ui/layers/site/layouts/landing.vue"),
  legal: () => import("/opt/render/project/src/apps/ui/layers/site/layouts/legal.vue"),
  public: () => import("/opt/render/project/src/apps/ui/layers/site/layouts/public.vue"),
  dashboard: () => import("/opt/render/project/src/apps/ui/core/layouts/dashboard.vue"),
  home: () => import("/opt/render/project/src/apps/ui/core/layouts/home.vue"),
  auth: () => import("/opt/render/project/src/apps/ui/layers/auth/layouts/auth.vue"),
  "onboarding-v2": () => import("/opt/render/project/src/apps/ui/layers/account/layouts/onboarding-v2.vue"),
  onboarding: () => import("/opt/render/project/src/apps/ui/layers/account/layouts/onboarding.vue"),
  "token-designer": () => import("/opt/render/project/src/apps/ui/layers/token-designer/layouts/token-designer.vue"),
  tokenomics: () => import("/opt/render/project/src/apps/ui/layers/token-designer/layouts/tokenomics.vue"),
  "automated-market-making": () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/layouts/automated-market-making.vue"),
  "engage-a-market-maker": () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/layouts/engage-a-market-maker.vue"),
  "exchange-listing-side-menu": () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/layouts/exchange-listing-side-menu.vue"),
  "market-maker-monitoring": () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/layouts/market-maker-monitoring.vue"),
  "portal-dashboard": () => import("/opt/render/project/src/apps/ui/layers/liquidity/layouts/portal-dashboard.vue")
}