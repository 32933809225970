import { initContract } from '@ts-rest/core'
import { GetMeResponse, TUpdateUser, UserResponse } from '../types'

const c = initContract()

export const usersContract = c.router({
  me: {
    method: 'GET',
    path: `/users/me`,
    responses: {
      200: GetMeResponse,
    },
    summary: 'Get the current user',
  },
  updateUser: {
    method: 'PATCH',
    path: `/users/me`,
    responses: {
      200: UserResponse,
    },
    body: TUpdateUser,
    summary: 'Update the current user',
  },
})

export type UsersContractType = typeof usersContract
