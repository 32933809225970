<script setup lang="ts">
import type { AreasOfInterestEnum } from '@forgd/contract'
import OnboardingV2Preference from './OnboardingV2Preference.vue'

const model = defineModel<Array<Preference>>()

const client = useClient()
const onboarding = useOnboardingV2()

function handlePreferenceUpdate(preference: Preference, value: boolean) {
  if (!model.value) {
    model.value = []
  }
  if (value) {
    model.value.push(preference)
  }
  else {
    model.value = model.value.filter(p => p.label !== preference.label)
  }
}

async function save() {
  const areasOfInterest = (model.value || []).map(p => p.slug as AreasOfInterestEnum)

  await client.onboarding.updateAreasOfInterest({
    body: {
      projectId: onboarding.projectId!,
      areasOfInterest,
    },
  })

  onboarding.next()
}

const canSubmit = computed(
  () => Boolean(model.value?.length),
)
</script>

<template>
  <div class="flex flex-wrap justify-center gap-4">
    <OnboardingV2Preference
      v-for="preference in onboarding.preferences"
      :key="preference.label"
      :label="preference.label"
      @update:model-value="handlePreferenceUpdate(preference, $event)"
    >
      <template #icon>
        <img :src="`/app/illustrations/onboarding/${preference.icon}`" alt="icon">
      </template>
      <template #label>
        {{ preference.label }}
      </template>
      <div v-html="preference.description" />
    </OnboardingV2Preference>
  </div>

  <OnboardingV2Continue class="w-[500px]" :disabled="!canSubmit" @click="save" />
</template>
