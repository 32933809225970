import { z } from 'zod'
import { customDateSchema } from '../common'

export const PostAmm2Lead = z.object({
  hasLiquidityPartner: z.boolean(),
  isListed: z.boolean(),
  name: z.string().trim().min(1),
  firstName: z.string().trim().min(1),
  lastName: z.string().trim().min(1),
  siteUrl: z.string().trim().optional().nullable(),
  tgeDate: customDateSchema.optional().nullable(),
  ticker: z
    .string()
    .trim()
    .min(1)
    .transform((val) => val.toUpperCase()),
})

export const GetAmm2LeadResult = PostAmm2Lead.extend({
  id: z.string().uuid(),
})

export const AcheronGetTradesSchema = z.object({
  id: z.number(),
  symbol: z.string(),
  price: z.number(),
  volume: z.number(),
  value_in_quote: z.number(),
  value_in_usd: z.number(),
  side: z.string(),
  timestamp: z.string(), // If the timestamp follows a specific format, you might want to use more specific validation.
  type: z.string(),
})
