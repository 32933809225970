<script setup lang="ts">
import { watchDebounced } from '@vueuse/core'
import {
  ComboboxAnchor,
  ComboboxContent,
  ComboboxGroup,
  ComboboxInput,
  ComboboxItem,
  ComboboxItemIndicator,
  ComboboxPortal,
  ComboboxRoot,
  ComboboxTrigger,
  ComboboxViewport,
} from 'radix-vue'

import type { CoinWithMarketData } from '~/server/routes/reference/projects'

export type SelectCoin = Pick<CoinWithMarketData, 'id' | 'name' | 'image' | 'symbol'>

const client = useClient()
const store = useOnboardingV2()

const search = ref<string>('')
const model = defineModel<SelectCoin | null>()

function handleTokenNotFound() {
  store.tokenFindState = 'token-not-found'
  isOpen.value = false
}

function reset() {
  store.tokenFindState = 'initial'
  search.value = ''
  model.value = null
}

const filteredCoinsApi = ref<Array<SelectCoin>>([])
watchDebounced(search, async (value) => {
  if (value.length > 1) {
    const coins = await client.coinGecko.searchCoins({
      query: {
        query: value,
      },
    })
    if (coins.status === 200) {
      filteredCoinsApi.value = coins.body.coins
    }
  }
}, {
  debounce: 500,
})

const isOpen = ref(false)

watch(model, (value) => {
  if (value) {
    isOpen.value = false
    store.tokenFindState = 'token-selected'
  }
})
</script>

<template>
  <ComboboxRoot
    v-model="model"
    v-model:open="isOpen"
    v-model:search-term="search"
    :display-value="(model) => model?.name"
    class="relative"
  >
    <ComboboxAnchor
      class="min-w-[460px] h-[60px] text-lg py-4 px-5 w-full inline-flex justify-between items-center rounded-lg outline-none border border-forgd-bgd-600 bg-forgd-neutral-100"
      :class="{ 'bg-white border-forgd-bgd-700': !!model }"
      v-bind="$attrs"
    >
      <div v-if="store.tokenFindState === 'initial'" class="w-full flex items-center justify-between">
        <ComboboxInput
          class="!bg-transparent outline-none border-0 focus:ring-0 p-0 w-full"
        />
        <ComboboxTrigger
          class="flex items-center justify-center"
        >
          <UIcon name="i-heroicons-magnifying-glass" class="h-5 w-5" />
        </ComboboxTrigger>
      </div>
      <div
        v-else-if="store.tokenFindState === 'token-not-found'"
        class="w-full flex items-center justify-between"
        @click="reset"
      >
        <div class="text-forgd-primary-900 text-normal">
          My token does not appear in this list
        </div>
        <UIcon name="i-heroicons-magnifying-glass" class="h-5 w-5" />
      </div>
      <div
        v-else-if="store.tokenFindState === 'token-selected'"
        class="w-full flex gap-2 items-center justify-start"
        @click="reset"
      >
        <img :src="model?.image" class="w-6 h-6 rounded-full">
        <span class="text-base">
          {{ model?.name }}
        </span>
      </div>
    </ComboboxAnchor>

    <ComboboxPortal class="w-full">
      <ComboboxContent
        align="start"
        position="popper"
        class="absolute top-0 z-50 h-[440px] mt-2 w-full min-w-[460px] bg-white overflow-hidden rounded-lg border border-forgd-bgd-600"
      >
        <ComboboxViewport class="flex flex-col space-y-3 font-display pt-2">
          <div v-if="!filteredCoinsApi.length" class="flex flex-col justify-center items-center h-3/4 p-4">
            <UIcon
              name="i-heroicons-magnifying-glass"
              class="w-12 h-12 text-forgd-primary-900 mb-2"
            />
            <div class="flex flex-col text-center text-forgd-primary-900 font-semibold text-normal mb-4 text-sm">
              <span>Sorry!</span>
              <span>There are no results to show based on your search.</span>
            </div>
            <span class="text-forgd-primary-900 text-normal text-sm">Please try a different query.</span>
          </div>

          <ComboboxGroup class="space-y-2 px-2">
            <ComboboxItem
              v-for="coin in filteredCoinsApi"
              :key="coin.id"
              :value="coin"
              class="p-2 text-sm hover:bg-forgd-bgd-400 rounded-lg relative flex gap-2 items-center"
            >
              <ComboboxItemIndicator class="absolute right-2 w-6 h-6">
                <UIcon
                  name="i-heroicons-check-circle-solid"
                  class="text-forgd-green-500 w-6 h-6"
                />
              </ComboboxItemIndicator>
              <img :src="coin.image" class="w-6 h-6 rounded-full">
              <span class="text-base font-semibold">
                {{ coin.name }}
              </span>
              <span class="text-sm text-forgd-gray-600 uppercase">
                {{ coin.symbol }}
              </span>
            </ComboboxItem>
          </ComboboxGroup>

          <div class="flex-grow flex justify-center items-end sticky bottom-0 bg-white">
            <div
              class="h-[50px] flex gap-1 justify-center items-center text-sm font-semibold text-forgd-primary-900 border-t border-forgd-bgd-600 cursor-pointer w-full"
              @click="handleTokenNotFound"
            >
              My token does not appear in this list
              <UIcon name="i-heroicons-arrow-right" class="h-5 w-5" />
            </div>
          </div>
        </ComboboxViewport>
      </ComboboxContent>
    </ComboboxPortal>
  </ComboboxRoot>
</template>
