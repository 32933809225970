import unhead_QzUSsxsjaB from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.10.0_jiti@2.4.0__ioredis@5.4.1__6uvng4yu3jpjoupuhk5jiwlkce/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_OoRpersdO5 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.10.0_jiti@2.4.0__ioredis@5.4.1__6uvng4yu3jpjoupuhk5jiwlkce/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_0Xg0t7ySbj from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+supabase@1.4.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_GGcpN4859t from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.10.0_jiti@2.4.0__ioredis@5.4.1__6uvng4yu3jpjoupuhk5jiwlkce/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_XQ0EdrKSV9 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.10.0_jiti@2.4.0__ioredis@5.4.1__6uvng4yu3jpjoupuhk5jiwlkce/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Yd70aAnRUm from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.10.0_jiti@2.4.0__ioredis@5.4.1__6uvng4yu3jpjoupuhk5jiwlkce/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_KUlLFUQL2Y from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.10.0_jiti@2.4.0__ioredis@5.4.1__6uvng4yu3jpjoupuhk5jiwlkce/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YMhi7Dmo9n from "/opt/render/project/src/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.21.3_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/apps/ui/.nuxt/components.plugin.mjs";
import prefetch_client_yoC6kFxtbF from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.10.0_jiti@2.4.0__ioredis@5.4.1__6uvng4yu3jpjoupuhk5jiwlkce/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import preview_client_enO1m7XrBk from "/opt/render/project/src/node_modules/.pnpm/@nuxthq+studio@2.2.1_magicast@0.3.5_rollup@4.21.3_webpack-sources@3.2.3/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.js";
import slideovers_oG11sHDhko from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui@2.18.6_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.21.3_t_ix2lj6p3y5mhgkxn2ibfui334y/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_gwVClhWoQp from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui@2.18.6_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.21.3_t_ix2lj6p3y5mhgkxn2ibfui334y/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_7jYcsKl749 from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui@2.18.6_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.21.3_t_ix2lj6p3y5mhgkxn2ibfui334y/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_f31VDMrr4w from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.21.3_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_HG3jrteju7 from "/opt/render/project/src/node_modules/.pnpm/@nuxt+icon@1.5.1_magicast@0.3.5_rollup@4.21.3_vite@5.4.8_@types+node@22.9.0_sass-embedded@1.7_nu2ipsbwvsedacwe5pwnub5o7m/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_DwUkijOjky from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui-pro@1.4.3_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.21._qzddmipowibehgfpundd7ti3ti/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_mRSYJC7CJ4 from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui-pro@1.4.3_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.21._qzddmipowibehgfpundd7ti3ti/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_fa7J0scKYm from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui-pro@1.4.3_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.21._qzddmipowibehgfpundd7ti3ti/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import feature_flags_client_yDiIZxtHJu from "/opt/render/project/src/apps/ui/core/plugins/feature-flags.client.ts";
import lottie_YCAtSIlAiw from "/opt/render/project/src/apps/ui/core/plugins/lottie.ts";
import openapi_ueP8qSReR8 from "/opt/render/project/src/apps/ui/core/plugins/openapi.ts";
import sentry_client_4BqsDhRlQY from "/opt/render/project/src/apps/ui/core/plugins/sentry.client.ts";
import tracing_client_Phhn2yfSIG from "/opt/render/project/src/apps/ui/core/plugins/tracing.client.ts";
export default [
  unhead_QzUSsxsjaB,
  router_OoRpersdO5,
  supabase_client_0Xg0t7ySbj,
  payload_client_GGcpN4859t,
  navigation_repaint_client_XQ0EdrKSV9,
  check_outdated_build_client_Yd70aAnRUm,
  chunk_reload_client_KUlLFUQL2Y,
  plugin_vue3_YMhi7Dmo9n,
  components_plugin_KR1HBZs4kY,
  prefetch_client_yoC6kFxtbF,
  preview_client_enO1m7XrBk,
  slideovers_oG11sHDhko,
  modals_gwVClhWoQp,
  colors_7jYcsKl749,
  plugin_client_f31VDMrr4w,
  plugin_HG3jrteju7,
  scrollbars_client_DwUkijOjky,
  presets_mRSYJC7CJ4,
  variables_fa7J0scKYm,
  feature_flags_client_yDiIZxtHJu,
  lottie_YCAtSIlAiw,
  openapi_ueP8qSReR8,
  sentry_client_4BqsDhRlQY,
  tracing_client_Phhn2yfSIG
]