import { z } from 'zod'
import { stringToIntNumber } from '../../helper'

export const GetVolumeBreakdownsQuery = z.object({
  date: z.string().date(),
  marketMaker: z.string().uuid().optional().nullable(),
  orderBook: z.string().optional().nullable(),
  exchange: z.string().optional().nullable(),
  daysLookback: z
    .string()
    .optional()
    .nullable()
    .transform(stringToIntNumber)
    .refine((val) => val == null || val > 0, {
      message: 'daysLookback must be a positive integer',
    }),
})

export type GetVolumeBreakdownsQueryType = z.infer<typeof GetVolumeBreakdownsQuery>

export const GetVolumeBreakdownsResult = z.array(
  z.object({
    allOthersMaker: z.number(),
    allOthersMakerPercentage: z.number(),
    allOthersMakerTaker: z.number(),
    allOthersMakerTakerPercentage: z.number(),
    allOthersTaker: z.number(),
    allOthersTakerPercentage: z.number(),
    maker: z.number(),
    makerPercentage: z.number(),
    makerTaker: z.number(),
    makerTakerPercentage: z.number(),
    taker: z.number(),
    takerPercentage: z.number(),
  }),
)

export type GetVolumeBreakdownsResultType = z.infer<typeof GetVolumeBreakdownsResult>
