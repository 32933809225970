import routerOptions0 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.10.0_jiti@2.4.0__ioredis@5.4.1__6uvng4yu3jpjoupuhk5jiwlkce/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui-pro@1.4.3_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.21._qzddmipowibehgfpundd7ti3ti/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}