<script setup lang="ts">
import type { InjectedFormGroupValue } from '#ui/types'
import {
  ComboboxAnchor,
  ComboboxContent,
  ComboboxInput,
  ComboboxItem,
  ComboboxItemIndicator,
  ComboboxPortal,
  ComboboxRoot,
  ComboboxTrigger,
  ComboboxViewport,
} from 'radix-vue'

export interface ComboboxOption {
  id?: string | number
  label: string
  value: string
}

defineProps<{
  options: Array<ComboboxOption>
  placeholder?: string
  active?: boolean
}>()

const emit = defineEmits(['open'])

const model = defineModel<Array<ComboboxOption>>()

const isOpen = ref(false)

function open() {
  isOpen.value = !isOpen.value
  if (isOpen.value) {
    emit('open')
  }
}

const showPlaceholder = computed(() => {
  return !model.value
})

const label = computed(() => {
  return model.value?.length && model.value.length > 0 ? `${model.value?.length} selected` : 'Select one or more'
})

/**
 * Use the values from FormGroup to set the error state.
 * See: https://github.com/nuxt/ui/blob/dev/src/runtime/components/forms/FormGroup.vue
 */
const formGroup = inject<InjectedFormGroupValue>('form-group')
const showErrorState = computed(() => formGroup?.error.value)

/**
 * Note for future explorers: the ComboboxInput with a button asChild is the magic incantation
 * to make this component work like a select menu rather than a text input.
 */
</script>

<template>
  <ComboboxRoot
    v-model="model"
    v-model:open="isOpen"
    multiple
    class="relative"
  >
    <ComboboxAnchor
      class="w-full h-[50px] inline-flex items-center rounded-lg outline-none border overflow-hidden"
      :class="{
        'bg-white': active,
        '!bg-transparent': !active,
        'border-forgd-bgd-600': !showErrorState,
        'border-forgd-red-500': showErrorState,
      }"
      v-bind="$attrs"
      @click="open"
    >
      <ComboboxTrigger
        class="flex items-center justify-center"
        @click="open"
      />
      <ComboboxInput
        as-child
        class="outline-none border-0 focus:ring-0 py-1.5 px-4 pr-2"
        :class="{ 'bg-white': active, '!bg-transparent': !active }"
      >
        <button type="button">
          <div class="text-base">
            <span v-if="showPlaceholder" class="text-text-placeholder font-normal">
              {{ placeholder || 'Select' }}
            </span>
            <span v-else>
              {{ label }}
            </span>
          </div>
        </button>
      </ComboboxInput>
    </ComboboxAnchor>

    <ComboboxPortal>
      <ComboboxContent
        align="start"
        :prioritize-position="true"
        position="popper"
        class="relative top-0 z-[100] w-[288px] my-2 p-1 min-w-[160px] bg-forgd-bgd-100 overflow-hidden rounded-lg border border-forgd-bgd-600"
      >
        <ComboboxViewport class="font-display">
          <ComboboxItem
            v-for="option in options"
            :key="option.value"
            :value="option"
            class="p-2 text-base hover:bg-forgd-bgd-200 rounded-md relative flex items-center"
          >
            <ComboboxItemIndicator class="absolute right-2 w-6 h-6">
              <UIcon
                name="i-heroicons-check-circle-solid"
                class="text-forgd-green-500 w-6 h-6"
              />
            </ComboboxItemIndicator>

            <div class="pr-6 truncate">
              <div class="text-base font-normal">
                {{ option.label }}
              </div>
            </div>
          </ComboboxItem>
        </ComboboxViewport>
      </ComboboxContent>
    </ComboboxPortal>
  </ComboboxRoot>
</template>
