<script setup lang="ts">
const dashboardPath = useRuntimeConfig().public.featureFlags.dashboard.path
const error = useError()
</script>

<template>
  <div class="w-full h-screen flex flex-col items-center justify-center">
    <div class="w-[640px] mx-auto flex flex-col gap-10 items-center justify-center flex-grow">
      <svg width="181" height="23" viewBox="0 0 181 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M86.0812 22.6966C84.2452 22.6966 82.5761 22.4152 81.0739 21.8525C79.5926 21.2898 78.2886 20.4978 77.162 19.4765C76.0562 18.4345 75.2008 17.2256 74.5957 15.8501C73.9907 14.4745 73.6881 12.9739 73.6881 11.3483C73.6881 9.72264 73.9907 8.22204 74.5957 6.84649C75.2008 5.47094 76.0562 4.27254 77.162 3.2513C78.2886 2.20922 79.5926 1.40681 81.0739 0.844088C82.5761 0.281363 84.2348 0 86.0499 0C87.8442 0 89.4924 0.281363 90.9946 0.844088C92.4968 1.40681 93.8008 2.20922 94.9066 3.2513C96.0124 4.27254 96.8678 5.47094 97.4728 6.84649C98.0779 8.22204 98.3804 9.72264 98.3804 11.3483C98.3804 12.9531 98.0779 14.4537 97.4728 15.8501C96.8678 17.2256 96.0124 18.4345 94.9066 19.4765C93.8008 20.4978 92.4968 21.2898 90.9946 21.8525C89.4924 22.4152 87.8546 22.6966 86.0812 22.6966ZM86.0499 17.6946C87.0514 17.6946 87.9694 17.5383 88.8039 17.2256C89.6594 16.913 90.4 16.4753 91.0259 15.9126C91.6518 15.3291 92.1317 14.6517 92.4655 13.8806C92.7994 13.1094 92.9663 12.2653 92.9663 11.3483C92.9663 10.4313 92.7994 9.58717 92.4655 8.81603C92.1317 8.04489 91.6518 7.37795 91.0259 6.81523C90.4 6.23166 89.6594 5.78357 88.8039 5.47094C87.9694 5.15831 87.0514 5.002 86.0499 5.002C85.0485 5.002 84.12 5.15831 83.2646 5.47094C82.4301 5.78357 81.6894 6.23166 81.0426 6.81523C80.4167 7.3988 79.9368 8.07615 79.603 8.84729C79.2901 9.61843 79.1336 10.4521 79.1336 11.3483C79.1336 12.2653 79.2901 13.1094 79.603 13.8806C79.9368 14.6517 80.4167 15.3291 81.0426 15.9126C81.6894 16.4753 82.4301 16.913 83.2646 17.2256C84.12 17.5383 85.0485 17.6946 86.0499 17.6946Z" fill="#000F21" />
        <path d="M140.968 22.6341C139.152 22.6341 137.494 22.3735 135.992 21.8525C134.51 21.3315 133.227 20.5812 132.142 19.6016C131.057 18.6012 130.212 17.4028 129.607 16.0064C129.023 14.5892 128.731 13.0156 128.731 11.2858C128.731 9.66012 129.033 8.16994 129.639 6.81523C130.264 5.43968 131.151 4.24128 132.299 3.22004C133.446 2.1988 134.792 1.40681 136.336 0.844089C137.901 0.281363 139.601 0 141.437 0C142.835 0 144.128 0.166733 145.318 0.500201C146.507 0.833667 147.571 1.27134 148.51 1.81323C149.47 2.35511 150.262 2.95952 150.888 3.62645L147.665 7.09659C147.102 6.61723 146.496 6.21082 145.85 5.87736C145.224 5.52305 144.535 5.24168 143.784 5.03327C143.054 4.80401 142.261 4.68938 141.406 4.68938C140.383 4.68938 139.434 4.85611 138.558 5.18958C137.702 5.52305 136.951 5.99199 136.305 6.59639C135.658 7.17996 135.157 7.87816 134.802 8.69098C134.448 9.48297 134.27 10.3479 134.27 11.2858C134.27 12.3487 134.458 13.297 134.834 14.1307C135.209 14.9435 135.72 15.6313 136.367 16.194C137.035 16.7567 137.807 17.1944 138.683 17.507C139.559 17.7988 140.498 17.9447 141.5 17.9447C142.313 17.9447 143.075 17.8405 143.784 17.6321C144.514 17.4236 145.151 17.1423 145.693 16.788C146.236 16.4337 146.643 16.0064 146.914 15.5062C147.132 15.1172 147.274 14.7108 147.341 14.287H140.968V10.1603H152.39C152.432 10.4313 152.464 10.7543 152.484 11.1295C152.505 11.5046 152.516 11.8589 152.516 12.1924C152.537 12.5259 152.547 12.7655 152.547 12.9114C152.547 14.412 152.255 15.7667 151.671 16.9755C151.107 18.1635 150.315 19.1744 149.292 20.008C148.27 20.8417 147.049 21.4878 145.631 21.9463C144.212 22.4048 142.658 22.6341 140.968 22.6341Z" fill="#000F21" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M158.624 22.3876V0.503797H168.795C170.569 0.503797 172.175 0.78516 173.615 1.34789C175.055 1.88977 176.286 2.66091 177.308 3.66131C178.351 4.66171 179.144 5.82885 179.686 7.16271C180.229 8.47574 180.5 9.9034 180.5 11.4457C180.5 13.0088 180.229 14.4573 179.686 15.7912C179.144 17.125 178.351 18.2817 177.308 19.2613C176.286 20.2409 175.055 21.012 173.615 21.5747C172.175 22.1166 170.569 22.3876 168.795 22.3876H158.624ZM168.639 17.6982H163.945V5.19318H168.639C169.682 5.19318 170.6 5.35991 171.393 5.69337C172.186 6.006 172.843 6.44368 173.365 7.0064C173.907 7.56913 174.324 8.23606 174.616 9.0072C174.908 9.7575 175.055 10.5703 175.055 11.4457C175.055 12.3419 174.908 13.1651 174.616 13.9154C174.324 14.6657 173.907 15.3222 173.365 15.885C172.843 16.4477 172.186 16.8958 171.393 17.2292C170.6 17.5419 169.682 17.6982 168.639 17.6982Z" fill="#000F21" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M104.59 0.503797V22.3876H109.66V14.7595H113.416L118.298 22.3876H124.432L119.011 14.0695C119.183 13.9932 119.352 13.9106 119.518 13.8216C120.708 13.1755 121.636 12.3106 122.304 11.2268C122.992 10.1222 123.336 8.87173 123.336 7.47534C123.336 6.09979 123.013 4.89097 122.366 3.84889C121.719 2.8068 120.812 1.99398 119.644 1.41041C118.475 0.806002 117.119 0.503797 115.575 0.503797H104.59ZM115.043 10.6954H109.66V4.94308H114.73C115.398 4.94308 115.971 5.0577 116.451 5.28696C116.952 5.51622 117.338 5.84969 117.609 6.28736C117.901 6.7042 118.047 7.19398 118.047 7.7567C118.047 8.34027 117.912 8.85089 117.641 9.28856C117.39 9.72624 117.036 10.0701 116.577 10.3202C116.138 10.5703 115.627 10.6954 115.043 10.6954Z" fill="#000F21" />
        <path d="M52.3904 0.532001V22.4158H57.7107V14.1625H67.694V9.72318H57.7107V5.22138H68.9772V0.532001H52.3904Z" fill="#000F21" />
        <path d="M9.65946 20.5696L18.2268 0.541715H40.1544L37.8767 5.81334C37.5352 6.6036 36.8964 7.20323 36.0619 7.51684L22.9037 12.4614C21.6183 12.9444 21.9853 14.8486 23.4699 15.3991L24.8113 15.8965C25.4752 16.1427 25.8731 16.8634 25.6489 17.4136L24.0139 21.4261C23.8234 21.8937 23.365 22.1789 22.8039 22.1789H10.9191C10.0734 22.1789 9.36059 21.2682 9.65946 20.5696Z" fill="#000F21" />
        <path d="M0.894801 4.29644C-0.743656 7.92087 2.96868 12.7324 7.40357 12.7324H8.14956C8.34337 12.7324 8.50267 12.6358 8.57203 12.4761L12.1259 4.29644H0.894801Z" fill="#000F21" />
      </svg>

      <div class="space-y-2 text-center">
        <h1 class="text-5xl">
          Something went wrong!
        </h1>
        <p class="text-xl text-text-body">
          We had problems processing your request.
          <br>
          Please return to the homepage to resume your journey, and try again.
        </p>

        
      </div>

      <UiButton @click="navigateTo(dashboardPath)">
        Back to Home
      </UiButton>
    </div>

    <div class="w-full">
      <AppFooter />
    </div>
  </div>
</template>
